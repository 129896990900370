<!--
    ModalPopup to be used to get user feedback or just notify user of something very important
    @props: title, body, buttons
    @emit: <whatever is passed in the 'buttons' prop>

    Example data to have prepared in parent if buttons are wanted in modal:
    modalButtons: [
      { title: 'Save',
        classes: 'outline-primary',
        emit: 'save' 
      },
      { title: "Don't Save",
        classes: 'outline-gray',
        emit: 'leave'
      },
      { title: 'Cancel',
        classes: 'outline-gray',
        emit: 'close' 
      },
    ]


    Example usage:
    <transition name="modal">
      <ModalPopup v-if="showModal" 
        title="Editor" 
        body="Do you want to save changes?" 
        :buttons="modalButtons"
        @save="save(); back();"
        @leave="back"
        @close="showModal = false">
      </ModalPopup>
    </transition>
    
-->

<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="py-2 px-4 mx-auto w-11/12 sm:w-2/3 md:w-1/2 lg:w-1/3 font-medium relative bg-white rounded-lg shadow-md transition-all duration-300"
      >
        <!-- Module Header -->
        <div
          @click="$emit('close')"
          class="absolute top-0 right-0 px-3 rounded-sm sm:hover:bg-red-400 text-4xl cursor-pointer leading-none font-normal"
        >
          &#215;
        </div>

        {{ title }}

        <h4 class="my-8" :class="{ 'pb-2': !buttons, 'pb-12': buttons }">{{ body }}</h4>

        <div
          v-if="buttons"
          class="absolute bottom-0 left-0 w-full bg-gray-200 text-right py-2 px-2 rounded-b"
        >
          <button
            v-for="(button, index) in buttons"
            :key="index"
            class="mx-1 px-3"
            :class="button.classes"
            @click="emitEvent(button)"
          >
            {{ button.title }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>

<script>
export default {
  props: {
    title: String,
    body: String,
    buttons: Array,
  },
  methods: {
    emitEvent(button) {
      this.$emit(button.emit);
      this.$emit('close');
    },
  },
};
</script>
